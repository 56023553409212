import caxios from "@/axios.js"
import { getLastArticuleID } from '../../helpers/matf'
export default {
    namespaced: true,
    state: {
        selected:{
            profsys: null,
            constrType: null,
            frame: null,
            sash: null,
            impost: null,
            stub: null,
            sill: null,
            chopstick: null,
            sashInSash:null,
            furniture: null,
            seal: null,
            ocolor: null,
            pcolor: null,
            panetype: null,
            lamtype: null,
            lamtypesType: null,
        },
        profsys:[],
        constrTypes:[],
        articuls:[],
        furnitures: [],
        seals:[],
        fdcolors:[],
        panetypes: [],
        lamtypes: [],
        lamtypesTypes: [],
        profTypeIds:"2,3,4,7,6,14,5,8,15,16,17,19,20"
    },
    getters: {
        constrTypes: (state) => () => {
            if (!state.selected.profsys) return []

            return state.constrTypes.filter(obj =>
                obj.profsys_ids.split(',').map(Number).includes(state.selected.profsys)
            );
        },
        profsys: state => state.profsys,
        articuls: state => state.articuls,
        furnitures: state => state.furnitures,
        seals: state => state.seals,
        fdcolors: state => state.fdcolors,
        panetypes: state => state.panetypes,
        lamtypes: state => state.lamtypes,
        lamtypesTypes: state => state.lamtypesTypes,
        selected: state => key => state.selected[key],
        allSelecteds: state => state.selected,
        articulTypes:  (state) => (ids) => {
            if (!state.selected.profsys || !state.selected.constrType) return []

            return state.articuls.filter(obj =>
                ids.includes(Number(obj.parenttip))
            );

        },
    },
    mutations: {
        setSelectedProfileSystem(state, value) {
            state.selected.profsys = value;
            // Прямая фильтрация без геттеров
            const filteredConstrTypes = state.constrTypes.filter(obj =>
                obj.profsys_ids.split(',').map(Number).includes(value)
            );
            // Установка значения constrType
            state.selected.constrType = filteredConstrTypes.length > 0
                ? filteredConstrTypes[0].id
                : null;
        },
        setSelectedConstrTypes(state, value) {
            state.selected.constrType = value;
        },
        updateSelected(state, {key, value}) {
            state.selected[key] = value
        },
        
    },
    actions: {
        async getProfsys({ state, commit }) {
            if(state.profsys.length > 0) return;
            return caxios
                .get("/getProfsys?count=all")
                .then(response => {
                    state.profsys = response.data.body?.items||[];
                    if(state.profsys.length){
                        commit('setSelectedProfileSystem', state.profsys[0].id);
                    }
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        async getConstrTypes({ state, commit, dispatch }) {
            if(state.constrTypes.length > 0) return;
            return caxios
                .get("/getConstrtypes?count=all")
                .then(response => {
                    state.constrTypes = response.data.body?.items||[];
                    if(state.constrTypes.length){
                        commit('setSelectedConstrTypes', state.constrTypes[0].id);
                    }
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        async getArticuls({ state, dispatch }, profTypeId) {
            // if(state.articuls.length > 0) return;
            state.articuls = [];
            const { profsys, constrType } = state.selected
            if(!profsys || !constrType) return null;
            return caxios
                .get(`/getArticuls?profTypeId=${state.profTypeIds}&profId=${profsys}&constrTypeId=${constrType}&count=all`)
                .then(response => {
                    state.articuls = response.data.body?.items||[];
                    dispatch('updateArticulTypes');
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        async getFurnitures({ state, commit, dispatch }) {
            state.furnitures = [];
            const { profsys} = state.selected
            if(!profsys) return null;
            return caxios
                .get(`/getFurniture?profId=${profsys}&count=all`)
                .then(response => {
                    state.furnitures = response.data.body?.items||[];
                    state.selected.furniture = state.furnitures.length > 0 ? state.furnitures[0].id : null;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        async getSeals({ state, commit, dispatch }) {
            state.seals = [];
            const { profsys} = state.selected
            if(!profsys) return null;
            return caxios
                .get(`/getSeals?profId=${profsys}&count=all`)
                .then(response => {
                    state.seals = response.data.body?.items||[];
                    state.selected.seal = state.seals.length > 0 ? state.seals[0].id : null;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        async getPanetypes({ state, commit, dispatch }) {
            state.panetypes = [];
            const { profsys} = state.selected
            if(!profsys) return null;
            return caxios
                .get(`/getPanetypes?profId=${profsys}&count=all`)
                .then(response => {
                    state.panetypes = response.data.body?.items||[];
                    state.selected.panetype = state.panetypes.length > 0 ? state.panetypes[0].id : null;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        async getLamtypes({ state, commit, dispatch }) {
            state.lamtypes = [];
            state.lamtypesTypes = [];
            const { profsys} = state.selected
            if(!profsys) return null;
            return caxios
                .get(`/getLamtypes?profId=${profsys}&count=all`)
                .then(response => {
                    state.lamtypes = response.data.body?.items||[];
                    state.selected.lamtype = state.lamtypes.length > 0 ? state.lamtypes[0].id : null;
                    dispatch('updateLamtypesType');
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        async getFdecors({ state, commit, dispatch }) {
            state.fdcolors = [];
            const { furniture} = state.selected
            if(!furniture) return null;
            return caxios
                .get(`/getFdecors?furnId=${furniture}&count=all`)
                .then(response => {
                    state.fdcolors = response.data.body?.items||[];
                    state.selected.pcolor = state.fdcolors.length > 0 ? state.fdcolors[0].id : null;
                    state.selected.ocolor = state.fdcolors.length > 0 ? state.fdcolors[0].id : null;
                })
                .catch(() => {
                    console.log('Error')
                });
        },
        updateArticulTypes({ state, getters }) {
            state.selected.frame = getLastArticuleID(getters.articulTypes([2]))
            state.selected.sash = getLastArticuleID(getters.articulTypes([7]))
            state.selected.impost = getLastArticuleID(getters.articulTypes([6]))
            state.selected.stub = getLastArticuleID(getters.articulTypes([14]))
            state.selected.sill = getLastArticuleID(getters.articulTypes([5]))
            state.selected.chopstick = getLastArticuleID(getters.articulTypes([8, 15]))
            state.selected.sashInSash = getLastArticuleID(getters.articulTypes([16]))
        },
        updateLamtypesType({ state, getters }) {
            state.lamtypesTypes = []
            state.selected.lamtypesType = null
            if(state.selected.lamtype){
                const lamtype = state.lamtypes.find(item => item.id === state.selected.lamtype);
                if(lamtype?.cn == 1){
                    state.lamtypesTypes.push({id:1, name:"Outdoor"})
                }
                if(lamtype?.cv == 1){
                    state.lamtypesTypes.push({id:2, name:"Internal"})
                }
                if(lamtype?.cd == 1){
                    state.lamtypesTypes.push({id:3, name:"Double sided"})
                }
                if(lamtype?.zvalue == 1){
                    state.lamtypesTypes.push({id:4, name:"On a brown basis (in bulk)"})
                }
                state.selected.lamtypesType = state.lamtypesTypes.length > 0 ? state.lamtypesTypes[0].id : null;
            }
        }
    }
};