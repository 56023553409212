<template>
  <div class="constrInfo">
    <div class="loader" v-if="!loaded" />
    <div class="constrInfo-content" v-else>
      <table class="table-header border w-100 mb-2">
        <tr>
          <td>Заказчик:</td>
          <td>Менеджер:</td>
          <td>Система:<strong></strong></td>
        </tr>
        <tr>
          <td><strong>№:</strong><strong></strong></td>
          <td>Укомплектовал:</td>
          <td>Фурнитура:</td>
        </tr>
        <tr>
          <td>Партия:</td>
          <td>Готовность:</td>
          <td>Цвет:</td>
        </tr>
        <tr>
          <td>Примечание:</td>
          <td>Количество: 1	</td>
          <td>Дата: 23.12.2024</td>
        </tr>
      </table>
      <table class="table-body w-100">
        <tr><td colspan="2" class="text-align-center">S = 1</td></tr>
        <tr>
          <td style="width: 50%"><img :src="constrImg" style="max-width: 100%;max-height: 400px;margin: auto;display: block"></td>
          <td style="width: 50%;vertical-align: top">
            <table class="w-100 row-border">
              <thead>
                <tr>
                  <th>№</th>
                  <th>Размер</th>
                  <th>К-во</th>
                </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
          </td>
        </tr>
      </table>
      <table class="table-body w-100 mt-3">
        <tr>
          <td style="width: 58%">
            <table class="w-100 row-border">
              <thead>
                <tr>
                  <th>Articuls</th>
                  <th>Наименование</th>
                  <th style="width: 10px"></th>
                  <th>Длина</th>
                  <th style="width: 30px">Уг1</th>
                  <th style="width: 30px">Уг2</th>
                  <th style="width: 30px">К-во</th>
                  <th>Итог</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in materials" :key="key">
                  <td>{{item.articul}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.lineType}}</td>
                  <td>{{Math.round(item.length * 100) / 100}}</td>
                  <td>{{Math.round(item.angle1 * 10) / 10}}</td>
                  <td>{{Math.round(item.angle2 * 10) / 10}}</td>
                  <td>{{item.q}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </td>
          <td></td>
          <td style="width: 39%;vertical-align: top">
            <table class="w-100 row-border">
              <thead>
                <tr>
                  <th>Наименование</th>
                  <th style="width: 30px">К-во</th>
                </tr>
              </thead>
              <tbody>

              </tbody>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div class="d-flex gap-2 mt-4">
      <button class="btn btn-outline-secondary" @click="getMaterialsSpec">Спецификация материалов</button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapMutations } from "vuex";
  import { toast } from 'vue3-toastify';
  import {findAngleB, getSelectionProf} from "@/helpers/frame";
  import caxios from "@/axios";
  import {calculateArcLength, getDistance} from "@/helpers/matf";
  import {checkConditions, checkAcceptance} from "@/utils/expressionParser";
 export default {
   name: "ConstrInfo",
   components: { },
   props: {
     constrImg: {
       type: String,
       required: true
     },
   },
   data() {
     return {
       materials:[],
       loaded: true,
       goods: []
     }
   },
   computed: {
     ...mapGetters("profile", ["profsys", "articuls", "allSelecteds"]),
     ...mapGetters("constr", ["addProfiles", "framePoints", "separators", "frameProfiles", "imposts", "framePolygons"]),
     frames() {
       return this.$store.getters['profile/articulTypes']([2,3,4,19,20]);
     },
     selectedFrame(){
       return this.$store.getters['profile/selected']("frame");
     },
     selectedProfileSystem() {
         return this.$store.getters['profile/selected']("profsys");
     }
   },
   mounted() {
     this.getMaterials();
   },
   methods: {
      getMaterialsSpec(){
        const data = {
          materials: JSON.stringify(this.materials)
        }
        this.loaded = false;
        return caxios
         .post("/exportMaterialsSpec", data)
         .then(response => {
           if(response.data.body?.url){
              const link = document.createElement('a');
              link.href = response.data.body.url;
              var filename = response.data.body.url.split('/').pop();
              link.setAttribute('download', filename);
              document.body.appendChild(link);
              link.click();
              link.remove();
           }
           this.loaded = true;
         })
         .catch(() => {
           console.log('Error')
           this.loaded = true;
         });
      },
     async getMaterials(){
        this.loaded = false;
        this.goods = []
        if(!this.selectedFrame){
          toast('Нет доступных рам', {
            autoClose: 1000,
            type: "warning",
          });
        }
        let goodsIds = [];
        this.frameProfiles.forEach(item => {
          const {p1} = item;
          const prof = getSelectionProf(this.frames, p1?.frame, this.selectedFrame, p1?.posType)
          if(prof){
              goodsIds.push(prof.or1_id);
          }
        })
        goodsIds = this.uniq(goodsIds);
        if(goodsIds.length){
          await this.getGoods(goodsIds)
        }
       let profs = []
       this.frameProfiles.forEach(item => {
         const {p1, p2, p3, p4, ligamentType1, ligamentType2} = item;
         const prof = getSelectionProf(this.frames, p1?.frame, this.selectedFrame, p1?.posType)
        //  console.log(prof)
         if(!prof) return;

         const good = this.goods.find(item => item.id === prof.or1_id);
         console.log(this.goods)
         if(good){
          let material = {
            gid: good.id,
            name: good.name,
            articul: good.articul,
            matgroup: good.matgroup,
            length:  p1?.r ? calculateArcLength(p1.x, p1.y, p2.x, p2.y, Math.abs(p1?.r)) : getDistance(p1.x, p1.y, p2.x, p2.y),
            lineType: this.getLineType(p1, p2),
            angle1: findAngleB(p3, p1, p2, p4, p1?.r),
            angle2: findAngleB(p4, p2, p1, p3, p1?.r*-1),
            ligamentType1,
            ligamentType2,
            q: 1,
            r: p1?.r
          }
          if(material.angle1 == 45){
            material.length += prof.axes_dist
          }
          if(material.angle2 == 45){
            material.length += prof.axes_dist
          }
          profs.push(material)
        }
       })
       const profsys = this.selectedProfileSystem ? this.profsys.find(item => item.id === this.selectedProfileSystem) : null;
       const sv = profsys?.sv;
       if(sv){
         profs.forEach((item, index) => {
           if(item.angle1 !== 90 && item.ligamentType1 !== 3){
             profs[index].length = profs[index].length+sv;
           }
           if(item.angle2 !== 90 && item.ligamentType2 !== 3){
             profs[index].length = profs[index].length+sv;
           }
         })
       }
       profs =  this.mergeMaterials(profs);
       this.getObjectMaterials(profs, 'prof')
     },
     getObjectMaterials(items, type){
        let data = [];
        items.forEach((item, index) => {
          this.getObjectMaterial(item, type)
        })
        return data;
     },
     async getObjectMaterial(item, type){
        const links = await this.getLinks(item.gid, 'all')
        const data = this.getConfData(type, item);
        const aData = this.getAConfData(type, item);
        let elem = {...item}
        if(!links){
          this.materials.push(item)
        }else{
          const substitutions = links.filter(i=>i.ztype == 1);
          let content = links.filter(i=>i.ztype != 1);
          let replace = null;
          if(substitutions.length){
            for (const item of substitutions) {
              if (checkConditions(item, data) && checkAcceptance(item, aData)){
                replace = item
                console.log(replace,1)
                break;
              }
            }
          }
          if(replace){
            console.log(replace,2)
            const rLinks = await this.getLinks(item.gid, -1)
            if(rLinks) content.push(...rLinks);

            elem.gid = replace.g_id
            elem.name = replace.g_name
          }
          this.materials.push(elem)
          content.forEach((item, index) => {
            if (checkConditions(item, data) && checkAcceptance(item, aData)){
              let contElem = {...elem}
              contElem.gid = item.g_id
              contElem.name = item.g_name
              this.materials.push(contElem)
            }
          })
        }
        console.log(links);
     },
     getConfData(type, elem){
      const oc = type == 'prof' ? 6 : 0;
      return {
        'PS': this.allSelecteds.profsys,
        'CT': this.allSelecteds.constrType,
        'S': this.allSelecteds.seal,
        'L': this.allSelecteds.lamtype,
        'C':0,
        'LS': this.allSelecteds.lamtypesType,
        'LM':0,
        'LSO':0,
        'LSI':0,
        'FS': this.allSelecteds.furniture,
        'FCT':0,
        'FD':0,
        'FSO':0,
        'FSI':0,
        'OT':0,
        'ST':0,
        'OC': oc
      }
     },
     getAConfData(type, elem){
      return {
        Угол1: elem.angle1,
        Угол2: elem.angle2,
        A1: elem.angle1,
        A2: elem.angle2,
        ПРОФИЛЬАРКА: elem.r > 0 ? 1 : 0,
        Длина: elem.length,
        ВЕРТИКАЛЬ: elem.lineType == '|' ? 1 : 0,
        ГОРИЗОНТАЛЬ: elem.lineType == '-' ? 1 : 0,
        ПОДУГЛОМ: elem.lineType == '/' ? 1 : 0,
        РАДИУС: elem.r,
        ФР: 0,
        PSID: this.allSelecteds.profsys,
      }
    },
     getLinks(pid, type){
      return caxios
         .get("/getLinks?pId="+pid+"&type="+type)
         .then(response => {
           return response.data.body?.items
         })
     },
     mergeMaterials(materials) {
       if(!materials.length) return [];
       const merged = {};

       materials.forEach(item => {
         // Ключ для идентификации одинаковых элементов
         const key = `${item.name}-${item.articul}-${item.length}-${item.lineType}-${item.angle1}-${item.angle2}`;

         if (!merged[key]) {
           // Если ключ ещё не существует, добавляем объект
           merged[key] = { ...item };
         } else {
           // Если ключ существует, увеличиваем `q`
           merged[key].q += item.q;
         }
       });

       // Преобразуем обратно в массив
       return Object.values(merged);
     },
     getGoods(goodsIds){
        return caxios
         .get("/getGoodsByIds?ids="+goodsIds.join(',')+"&count=all")
         .then(response => {
           if(response.data.body.items.length){
             this.goods = response.data.body.items;
           }
           this.loaded = true;
         })
         .catch(() => {
           console.log('Error')
           this.loaded = true;
         });
     },
     uniq(array) {
       return [...new Set(array)];
     },
     getLineType(p1, p2) {
      // Если p1 имеет параметр r, это арка
      if (p1?.r) {
        return '^'; // Арка
      }

      // Проверяем тип линии
      if (p1.y === p2.y) {
        return '-'; // Горизонтальная линия
      } else if (p1.x === p2.x) {
        return '|'; // Вертикальная линия
      } else {
        return '/'; // Линия с наклоном
      }
    }
   }
 }
</script>
<style scoped>
 .constrInfo{
   width: 756px;
   margin: auto;
 }
 table{
   text-align: left;
 }
 .text-align-center{
   text-align: center;
 }
 .row-border td, .row-border th, .row-border tr{
   border-width: 1px;
   font-size: 12px;
 }
 .row-border thead{
   background:  #E4E4E4;
   text-align: center;
 }
 .row-border tbody{
   text-align: center;
 }
 .table-header td{
   font-size: 14px;
   padding: 3px 5px;
 }
</style>