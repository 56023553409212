<template>
  <div>
    <header class="p-3 text-bg-dark">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
          <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
            <img class="bi me-2" style="height: 40px;" src="/assents/logo.png"/>
          </a>
          <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0" v-show="user">
            <li v-for="(item, key) in menus" :key="key">
              <router-link :to="item.path" class="nav-link text-white fw-semibold">{{item.name}}</router-link>
            </li>
          </ul>
          <div class="text-end" v-show="user">
            <button type="button" class="btn btn-outline-light me-2" @click="logout">Logout</button>
          </div>
        </div>
    </header>
    <router-view/>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
      return {
          menus: []
      }
  },
  computed: {
    ...mapGetters("auth", ["user"])
  },
  created() {
      this.$router.options.routes.forEach(route => {
        if(route.path != '/'){
          this.menus.push({
              name: route.name,
              path: route.path
          })
        }
      })
  },
  mounted() {
    if (localStorage.getItem("authToken")) {
      this.getUserData().then(() => {
        this.$router.push({ name: "Home" });
      });
    }
  },

  methods: {
    ...mapActions("auth", ["sendLogoutRequest", "getUserData"]),

    logout() {
      this.sendLogoutRequest().then(() => {
        this.$router.push({ name: "Home" });
      });
    }
  }
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  min-width: 500px;
  min-height: 500px;
}
#app > div{
  height: 100%
}
#app > div > div{
  height: calc(100% - 75px)
}
#app main{
  height: 100%;
}
#app main .constructor{
  width: calc(100% - 300px);
  height: 100%;
}
.sidebar{
  height: 100%;
  overflow: auto;
}
a.router-link-exact-active.nav-link.text-white {
    color: #3490dc !important;
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side,#ffa516 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#ffa516);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation: l13 1s infinite linear;
  margin: auto;
}
@keyframes l13{
  100%{transform: rotate(1turn)}
}
</style>
