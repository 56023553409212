export function tokenize(expression) {
    return expression.match(/\(|\)|[A-ZА-Я_]+|\d+(\.\d+)?|[=!><]+|[\w\p{L}]+|\[.*?\]/gu) || [];
}

export function parseExpression(tokens) {
    let result = [];

    while (tokens.length > 0) {
        let token = tokens.shift();

        if (token === "(") {
            result.push(parseExpression(tokens));
        } else if (token === ")") {
            break;
        } else if (["И", "ИЛИ", "AND", "OR"].includes(token)) {
            result.push(token === "ИЛИ" || token === "OR" ? "OR" : "AND");
        } else if (/^\[.+\]$/.test(token)) {
            let left = token.replace(/\[|\]/g, "");
            let operator = tokens.shift();
            let right = tokens.shift();
            result.push({ left, operator, right });
        } else if (token === "НЕ") {
            result.push({ operator: "НЕ", value: parseExpression(tokens) });
        } else if (["SIN", "COS", "ABS", "CEIL", "FLOOR", "ROUND"].includes(token)) {
            tokens.shift();
            let arg = parseExpression(tokens);
            tokens.shift();
            result.push({ function: token, args: [arg] });
        } else if (["MIN", "MAX"].includes(token)) {
            tokens.shift();
            let args = [];
            while (tokens[0] !== ")") {
                args.push(parseExpression(tokens));
                if (tokens[0] === ";") tokens.shift();
            }
            tokens.shift();
            result.push({ function: token, args });
        } else if (token === "IN") {
            tokens.shift();
            let field = tokens.shift()?.replace(/\[|\]/g, "");
            tokens.shift();
            let values = [];
            while (tokens[0] !== ")") {
                values.push(tokens.shift());
                if (tokens[0] === ";") tokens.shift();
            }
            tokens.shift();
            result.push({ function: "IN", field, values });
        }
    }

    return result;
}

export function evaluateExpression(ast, values) {
    if (!Array.isArray(ast)) return false;

    let result = null;
    let prevLogic = null;

    for (let item of ast) {
        let isTrue = false;

        if (item.left) {
            let left = item.left;
            let operator = item.operator;
            let right = isNaN(Number(item.right)) ? item.right : Number(item.right);
            let value = values[left] ?? null;

            switch (operator) {
                case "=": case "==": isTrue = value == right; break;
                case "!=": case "<>": isTrue = value != right; break;
                case ">": isTrue = value > right; break;
                case "<": isTrue = value < right; break;
                case ">=": isTrue = value >= right; break;
                case "<=": isTrue = value <= right; break;
            }
        } else if (item.operator === "НЕ") {
            isTrue = !evaluateExpression(item.value, values);
        } else if (item.function) {
            let args = item.args.map(arg => evaluateExpression(arg, values));

            switch (item.function) {
                case "SIN": isTrue = Math.sin(args[0] * (Math.PI / 180)) > 0; break;
                case "COS": isTrue = Math.cos(args[0] * (Math.PI / 180)) > 0; break;
                case "ABS": isTrue = Math.abs(args[0]) > 0; break;
                case "CEIL": isTrue = Math.ceil(args[0]) > 0; break;
                case "FLOOR": isTrue = Math.floor(args[0]) > 0; break;
                case "ROUND": isTrue = Math.round(args[0]) > 0; break;
                case "MIN": isTrue = Math.min(...args) > 0; break;
                case "MAX": isTrue = Math.max(...args) > 0; break;
            }
        } else if (item.function === "IN") {
            let field = item.field;
            isTrue = item.values.includes(values[field]?.toString());
        } else {
            prevLogic = item;
            continue;
        }

        if (result === null) {
            result = isTrue;
        } else {
            if (prevLogic === "AND" || prevLogic === "И") {
                result = result && isTrue;
            } else if (prevLogic === "OR" || prevLogic === "ИЛИ") {
                result = result || isTrue;
            }
        }
    }

    return result ?? false;
}

export function parseConditions(expression) {
    const conditions = {};

    expression.split("&").forEach(condition => {
        let [key, value] = condition.trim().split(/(!?=)/);
        if (!key || !value) return;

        let operator = value.startsWith("!=") ? "NOT IN" : "IN";
        let values = value.replace(/(!?=)/, "").split(",").map(v => v.trim());

        conditions[key.trim()] = { type: operator, values };
    });

    return conditions;
}

export function evaluateConditions(conditions, values) {
    return Object.entries(conditions).every(([key, condition]) => {
        if (!(key in values)) return false;

        let value = values[key]?.toString();
        let valuesSet = new Set(condition.values);

        return condition.type === "IN" ? valuesSet.has(value) : !valuesSet.has(value);
    });
}

export function checkConditions(item, data) {
    let result = true;
    if(item.confexpr){
        // console.log(item, data)
        const conditions = item.confexpr.split(' & ');
        for (const conditem of conditions) {
            const cond = conditem.split('=');
            var operator = true
            if (cond.length > 1){
              const vals = cond[1].split(",").map(Number);
              let varible = cond[0];
              if(varible.substring(varible.length - 1) == '!'){
                varible = varible.substring(0,varible.length - 1)
                operator = false
              }
              if(!data[varible] || ( operator && !vals.includes(Number(data[varible])) ) || ( !operator && vals.includes(Number(data[varible])) ) ){
                result = false;
                break;
              }
            }
        }
    }
    
    return result;
}

export function checkAcceptance(item, values) {
    let expression = item.zrem;
    if(!expression) return true;

    expression = expression.replace(/\[([\wА-Яа-я]+)\]/g, (_, key) => {
        return values[key] !== undefined ? JSON.stringify(values[key]) : "null";
    });
    console.log(expression);
    // Обрабатываем операторы
    expression = expression
        .replace(/\s*ИЛИ\s*/g, " || ")  
        .replace(/\s*И\s*/g, "&&")    
        .replace(/\s*NOT\s*\((.*?)\)/g, "!($1)")  
        .replace(/\s*НЕ\s*\((.*?)\)/g, "!($1)")
        .replace(/<>/g, "!=")  
        .replace(/=/g, "==")  
        .replace(/\s*MIN\s*\((.*?)\)/g, "Math.min($1)")  
        .replace(/\s*MAX\s*\((.*?)\)/g, "Math.max($1)")  
        .replace(/\s*ABS\s*\((.*?)\)/g, "Math.abs($1)")  
        .replace(/\s*SIN\s*\((.*?)\)/g, "Math.sin($1 * Math.PI / 180)")  
        .replace(/\s*COS\s*\((.*?)\)/g, "Math.cos($1 * Math.PI / 180)")  
        .replace(/\s*ROUND\s*\((.*?)\)/g, "Math.round($1)")  
        .replace(/\s*RUP\s*\((.*?)\)/g, "Math.ceil($1)")  
        .replace(/\s*RDN\s*\((.*?)\)/g, "Math.floor($1)")  
        .replace(/\s*IN\s*\(([^;]+);(.*?)\)/g, "[$2].includes($1)");

    console.log(expression);
    try {
        return eval(expression); // Выполняем вычисления
    } catch (e) {
        console.error("Ошибка в выражении:", expression, e);
        return false;
    }
}
