import caxios from "@/axios.js"
import {lineLinesIntersections, moveLinePerpendicular, normalizeLine, classifyEdges} from '../../helpers/matf'
import { getInsetPolygon } from '../../helpers/frame'
import {toast} from "vue3-toastify";
import {getPolygons} from "@/helpers/splitPolygons";
export default {
  namespaced: true,

  state: {
    dividers: null,
    dImposts: null,
    dShtulp: null,
    addProfiles: null,
    framePoints: [
      { ox: 0, oy: 0, or: 900},
      { ox: 1000, oy: 0},
      { ox: 1000, oy: 1000 },
      { ox: 0, oy: 1000 }
    ],
    frameProfiles: [],
    framePolygons:[],
    separators:[],
    addProfs:[],
    imposts:[],
    frames:[],
    selectedProfile:{}
  },

  getters: {
    dividers: state => state.dividers,
    dImposts: state => state.dImposts,
    dShtulp: state => state.dShtulp,
    addProfiles: state => state.addProfiles,
    framePoints: state => state.framePoints,
    framePolygons: state => state.framePolygons,
    separators: state => state.separators,
    addProfs: state => state.addProfs,
    imposts: state => state.imposts,
    frames: state => state.frames,
    frameProfiles: state => state.frameProfiles,
    getAllLines: (state) => (k = '', k1 = '') => {
      let lines = [];
      state.framePoints.forEach((point, index) => {
        let nextIndex = (index + 1) % state.framePoints.length;
        lines.push({
          x1: state.framePoints[index][`${k}x${k1}`],
          y1: state.framePoints[index][`${k}y${k1}`],
          x2: state.framePoints[nextIndex][`${k}x${k1}`],
          y2: state.framePoints[nextIndex][`${k}y${k1}`],
          r: state.framePoints[index][`${k}r${k1}`]
        });
      });
      return lines;
    },
    getPolygonFrame: (state) => (points) =>{
      let renderPoints = []
      const ppp = classifyEdges(state.framePoints)
      points.forEach(point => {
        let p = {
          ...point,
          ox: point.x,
          oy: point.y,
          or: point?.r,
        }
        if(point?.type === 'separator'){
          p.frame = state.separators[point?.index]?.frame
        }else{
          p.frame = state.framePoints[point?.index]?.frame
          p.posType  = ppp[point?.index]?.posType
        }
        renderPoints.push(p)
      })
      return renderPoints;
    }
  },
  mutations: {
    setDividers(state, data) {
      state.dividers = data;
    },
    setaddProfiles(state, data) {
      state.addProfiles = data;
    },
    setImposts(state, data) {
      state.dImposts = data;
    },
    setShtulp(state, data) {
      state.dShtulp = data;
    },
    setFrames(state, data) {
      state.frames = data;
    },
    updateStateItem(state, {property, value}) {
      if (Object.prototype.hasOwnProperty.call(state, property)){
        state[property] = value;
      }
    },
    addFramePointinIndex(state, {point, k}) {
      state.framePoints.splice(k, 0, point);
    },
    removeArrayItem(state, {property, index}){
      if (Object.prototype.hasOwnProperty.call(state, property) && state[property][index]){
        state[property].splice(index, 1);
      }
    },
    updateArrayItem(state, {property, index, item}){
      if (Object.prototype.hasOwnProperty.call(state, property) && state[property][index]){
        state[property][index]=item;
      }
    },
    addArrayItem(state, {property, item}){
      if (Object.prototype.hasOwnProperty.call(state, property)){
        console.log(item);
        state[property].push(item);
        console.log(state[property]);
      }
    },
    updateArrayItemProperty(state, {property, index, key, value}){
      if (Object.prototype.hasOwnProperty.call(state, property) && state[property][index]) {
        state[property][index][key] = value;
      }
    }
  },
  actions: {
    updateImposts({ state, commit, getters, rootGetters }, payload={}){
      let lines = [];
      state.frameProfiles.map(function(profile, i) {
        lines.push({
          x1: profile.p3.x,
          y1: profile.p3.y,
          x2: profile.p4.x,
          y2: profile.p4.y,
          r: profile.p3.r
        })
      })
      state.imposts.map(function(line, i) {
        const prof = line.prof ? line.profType === 1 ? rootGetters['profile/articulTypes']([6]).find(obj => obj.id === line.prof) : rootGetters['profile/articulTypes']([14]).find(obj => obj.id === line.prof) : null;
        let distance = prof ? prof.wr : 40;
        const data = lineLinesIntersections(line, lines);
        let copyLine = {...line}
        if(data.length){
          if(data[0] && line.ox2 === data[0].x && line.oy2 === data[0].y && data[1]){
            copyLine.ox1 = data[1].x
            copyLine.oy1 = data[1].y
          }else if(data[0] && line.ox1 === data[0].x && line.oy1 === data[0].y && data[1]){
            copyLine.ox2 = data[1].x
            copyLine.oy2 = data[1].y
          }else if(data[1] && line.ox1 === data[1].x && line.oy1 === data[1].y && data[0]){
            copyLine.ox2 = data[0].x
            copyLine.oy2 = data[0].y
          }else if(data[1] && line.ox2 === data[1].x && line.oy2 === data[1].y && data[0]){
            copyLine.ox1 = data[0].x
            copyLine.oy1 = data[0].y
          }else{
            if(data[0]){
              copyLine.ox1 = data[0].x
              copyLine.oy1 = data[0].y
            }
            if(data[1]){
              copyLine.ox2 = data[1].x
              copyLine.oy2 = data[1].y
            }
          }
        }
        // copyLine = normalizeLineO(copyLine)
        commit('updateArrayItem', {property: 'imposts', index: i, item: copyLine})
        if(distance >= 10){
          const mlp = moveLinePerpendicular(copyLine.ox1, copyLine.oy1, copyLine.ox2, copyLine.oy2, distance, 2)
          state.imposts[i].drawLines = []
          mlp.map(function(lineup, j) {
            let newL = {
              ox1: lineup.x1,
              oy1: lineup.y1,
              ox2: lineup.x2,
              oy2: lineup.y2,
              lt: '-'
            };
            const data = lineLinesIntersections(newL, lines);
            if(data.length){
              if(data[0] && newL.ox2 === data[0].x && newL.oy2 == data[0].y && data[1]){
                newL.ox1 = data[1].x
                newL.oy1 = data[1].y
                newL.or1 = data[1].line ? data[1].line.r : null
              }else if(data[0] && newL.ox1 === data[0].x && newL.oy1 === data[0].y && data[1]){
                newL.ox2 = data[1].x
                newL.oy2 = data[1].y
                newL.or2 = data[1].line ? data[1].line.r : null
              }else if(data[1] && newL.ox1 === data[1].x && newL.oy1 === data[1].y && data[0]){
                newL.ox2 = data[0].x
                newL.oy2 = data[0].y
                newL.or2 = data[0].line ? data[0].line.r : null
              }else if(data[1] && newL.ox2 === data[1].x && newL.oy2 === data[1].y && data[0]){
                newL.ox1 = data[0].x
                newL.oy1 = data[0].y
                newL.or1 = data[0].line ? data[0].line.r : null
              }else{
                if(data[0]){
                  newL.ox1 = data[0].x
                  newL.oy1 = data[0].y
                  newL.or1 = data[0].line ? data[0].line.r : null
                }
                if(data[1]){
                  newL.ox2 = data[1].x
                  newL.oy2 = data[1].y
                  newL.or2 = data[1].line ? data[1].line.r : null
                }
              }
            }
            newL = {
              x1:newL.ox1,
              y1:newL.oy1,
              x2:newL.ox2,
              y2:newL.oy2
            }
            state.imposts[i].drawLines.push(newL)
            if (j === 0 || j === mlp.length - 1) {
              lines.push({
                index:i,
                lt: '-',
                r:line?.or,
                ...newL
              })
            }
          })
        }else{
          lines.push({
            x1:copyLine.ox1,
            y1:copyLine.oy1,
            x2:copyLine.ox2,
            y2:copyLine.oy2,
            r:line?.or,
            index:i
          })
          state.imposts[i].drawLines = []
        }
      })
    },
    updateSeparators({ state, commit, getters, rootGetters }){
      const _self = this;
      const dividers = rootGetters['profile/articulTypes']([17]);
      let lines = getters.getAllLines('o');
      const getRoundedSteps = function (distance, d) {
        let steps = Math.floor(distance / d);
        if (steps % 2 !== 0) {
          steps--; // Если количество шагов нечетное, уменьшаем на 1
        }
        return Math.max(steps, 2);
      };
      state.separators.map(function(line, i) {
        const prof = line.prof ? dividers.find(obj => obj.id === line.prof) : null;
        let distance = prof && (!line.fixedDimension || parseInt(line.fixedDimension) < 1) ? prof.wr : parseInt(line.fixedDimension)*2;
        const data = lineLinesIntersections(line, lines);
        let copyLine = {...line}
        if(data.length){
          if(data[0] && copyLine.ox2 === data[0].x && copyLine.oy2 === data[0].y && data[1]){
            copyLine.ox1 = data[1].x
            copyLine.oy1 = data[1].y
          }else if(data[0] && copyLine.ox1 === data[0].x && copyLine.oy1 === data[0].y && data[1]){
            copyLine.ox2 = data[1].x
            copyLine.oy2 = data[1].y
          }else if(data[1] && copyLine.ox1 === data[1].x && copyLine.oy1 === data[1].y && data[0]){
            copyLine.ox2 = data[0].x
            copyLine.oy2 = data[0].y
          }else if(data[1] && copyLine.ox2 === data[1].x && copyLine.oy2 === data[1].y && data[0]){
            copyLine.ox1 = data[0].x
            copyLine.oy1 = data[0].y
          }else{
            if(data[0]){
              copyLine.ox1 = data[0].x
              copyLine.oy1 = data[0].y
            }
            if(data[1]){
              copyLine.ox2 = data[1].x
              copyLine.oy2 = data[1].y
            }
          }
        }
        commit('updateArrayItem', {property: 'separators', index: i, item: copyLine})
        if(distance >= 10 && !copyLine.notIncDimensions){
          const mlp = moveLinePerpendicular(copyLine.ox1, copyLine.oy1, copyLine.ox2, copyLine.oy2, distance, getRoundedSteps(distance, 20))
          state.separators[i].drawLines = []
          mlp.map(function(linelp, j) {
            let newL = {
              ox1: linelp.x1,
              oy1: linelp.y1,
              ox2: linelp.x2,
              oy2: linelp.y2,
              lt: '-'
            };
            const data = lineLinesIntersections(newL, lines);
            if(data.length){
              if(data[0] && newL.ox2 === data[0].x && newL.oy2 === data[0].y && data[1]){
                newL.ox1 = data[1].x
                newL.oy1 = data[1].y
                newL.or1 = data[1].line ? data[1].line.r : null
              }else if(data[0] && newL.ox1 === data[0].x && newL.oy1 === data[0].y && data[1]){
                newL.ox2 = data[1].x
                newL.oy2 = data[1].y
                newL.or2 = data[1].line ? data[1].line.r : null
              }else if(data[1] && newL.ox1 === data[1].x && newL.oy1 === data[1].y && data[0]){
                newL.ox2 = data[0].x
                newL.oy2 = data[0].y
                newL.or2 = data[0].line ? data[0].line.r : null
              }else if(data[1] && newL.ox2 === data[1].x && newL.oy2 === data[1].y && data[0]){
                newL.ox1 = data[0].x
                newL.oy1 = data[0].y
                newL.or1 = data[0].line ? data[0].line.r : null
              }else{
                if(data[0]){
                  newL.ox1 = data[0].x
                  newL.oy1 = data[0].y
                  newL.or1 = data[0].line ? data[0].line.r : null
                }
                if(data[1]){
                  newL.ox2 = data[1].x
                  newL.oy2 = data[1].y
                  newL.or2 = data[1].line ? data[1].line.r : null
                }
              }
            }
            newL = normalizeLine({
              x1:newL.ox1,
              y1:newL.oy1,
              x2:newL.ox2,
              y2:newL.oy2
            })
            state.separators[i].drawLines.push(newL)
            if (j === 0 || j === mlp.length - 1) {
              lines.push({
                index:i,
                lt: '-',
                ...newL
              })
            }
          })
        }else{
          state.separators[i].drawLines = []
          lines.push({
            x1:copyLine.ox1,
            y1:copyLine.oy1,
            x2:copyLine.ox2,
            y2:copyLine.oy2,
            index:i
          })
        }
      })
    },
    frameProfiles({state, commit, getters, rootGetters}, payload= {property:'frameProfiles', type: 1} ){
      let polygon = []
      for (let i = 0; i < state.framePoints.length; i++) {
        polygon.push({
          x: state.framePoints[i].ox,
          y: state.framePoints[i].oy,
          r: state.framePoints[i].or,
          type: 'frameLine',
          index: i,
        })
      }
      let lines = [];
      for (let i = 0; i < state.separators.length; i++) {
        const ll = state.separators[i];
        if(ll.drawLines.length) {
          lines.push([
            {
              x: ll.drawLines[0].x1,
              y: ll.drawLines[0].y1,
              type: 'separator',
              index: i
            },
            {
              x: ll.drawLines[0].x2,
              y: ll.drawLines[0].y2,
              type: 'separator',
              index: i
            },
            {
              x: ll.drawLines[1].x1,
              y: ll.drawLines[1].y1,
              type: 'separator',
              index: i
            },
            {
              x: ll.drawLines[1].x2,
              y: ll.drawLines[1].y2,
              type: 'separator',
              index: i
            },
          ])
        }else{
          lines.push([
            {
              x: ll.ox1,
              y: ll.oy1,
              type: 'separator',
              index: i
            },
            {
              x: ll.ox2,
              y: ll.oy2,
              type: 'separator',
              index: i
            }
          ])
        }
      }
      const polygons = getPolygons(polygon, lines);
      const frames = rootGetters['profile/articulTypes']([2,3,4,19,20]);
      const selFrame = rootGetters['profile/selected']("frame");
      let combinedResults = [];
      polygons.forEach((polygon) => {
        polygon = getters.getPolygonFrame(polygon);
        const cords = getInsetPolygon(polygon, frames, selFrame, payload.type);
        if (cords instanceof Array) {
          if(payload.type === 2){
            combinedResults.push(cords);
          }else{
            combinedResults = combinedResults.concat(cords);
          }
        } else {
          // Если cords не массив, показываем предупреждение для конкретного полигона
          toast(`Ошибка в полигоне с ${cords || "неизвестно"}`, {
            autoClose: 1000,
            type: "warning",
          });
        }
      });
      commit('updateStateItem', {property:payload.property,value:combinedResults})
    },
    getDividers({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=17&count=all&sortCol=wr")
        .then(response => {
          commit("setDividers", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getaddProfiles({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=17&count=all")
        .then(response => {
          commit("setaddProfiles", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getImposts({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=6&count=all&sortCol=wr")
        .then(response => {
          commit("setImposts", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getShtulp({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=14&count=all&sortCol=wr")
        .then(response => {
          commit("setShtulp", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    },
    getFrames({ commit }) {
      caxios
        .get("/getArticuls?profTypeId=14&count=all&sortCol=wr")
        .then(response => {
          commit("setFrames", response.data.body.items);
        })
        .catch(() => {
          console.log('Error')
        });
    }
  }
};